import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="能源开放平台"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="管理中心_SDK" transform="translate(-1400.000000, -26.000000)">
        <g id="用户" transform="translate(1288.000000, 16.000000)">
          <g id="Bicon/12/down" transform="translate(112.000000, 10.000000)">
            <rect id="Rectangle-2" x="0" y="0" width="12" height="12"></rect>
            <path
              d="M6,7.4225064 L9.82606132,3.4818771 C10.0127706,3.289577 10.3200184,3.28504473 10.5123185,3.47175399 C10.5157424,3.4750784 10.5191172,3.47845314 10.5224416,3.4818771 C10.716381,3.68162384 10.716381,3.99936311 10.5224416,4.19910986 L6.46361593,8.37947341 C6.44002108,8.42941171 6.40794336,8.47634778 6.3673828,8.5181229 C6.36405838,8.52154686 6.36068365,8.5249216 6.35725968,8.52824601 C6.25800298,8.62461697 6.12812235,8.67003809 6,8.66498703 C5.87187765,8.67003809 5.74199702,8.62461697 5.64274032,8.52824601 C5.63931635,8.5249216 5.63594162,8.52154686 5.6326172,8.5181229 C5.59205664,8.47634778 5.55997892,8.42941171 5.53638407,8.37947341 L1.47755841,4.19910986 C1.28361901,3.99936311 1.28361901,3.68162384 1.47755841,3.4818771 C1.48088282,3.47845314 1.48425756,3.4750784 1.48768152,3.47175399 C1.67998163,3.28504473 1.98722942,3.289577 2.17393868,3.4818771 L6,7.4225064 Z"
              id="路径"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const DownIcon = (props) => <Icon component={svg} {...props} />;
