import { SystemInfoConfig } from "./model/data";

export const systemConfig:SystemInfoConfig = {
  key:'dongfeng',
  name: "东风本田充电管理云平台",
  logo:{
    url: "https://view.didistatic.com/static/dcms/2pi7l512em9lgw0a21z_240x64_compress.png",
    width: 120,
    height: 32
  },
  // loginBackgroundImg: 'https://view.didistatic.com/static/dcms/f63wydb3lgvzq12z_1440x810_compress.png',
  loginBackgroundImg: 'https://view.didistatic.com/static/dcms/fhq5i13b6hlgzyfc7k_2880x1620.jpeg',
  whiteLogo:{
    url:"https://view.didistatic.com/static/dcms/2pi7l513dymlgw66p4r_450x105_compress.png",
    width: 225,
    height:53
  },
  antdTheme: {
    token: {
      colorPrimary:  "#3698FF",
    },
  }
};
// 前端路由和menuKey映射
export const routerKeyMap = {
  home: "/base/home",
  account_center: "/base/account-center",
  account_center_role_manage: "/base/account-center/role-manage",
  account_center_user_manage: "/base/account-center/user-manage",
  charge_api_devops: "/charge-api/devops",
  charge_api_devops_overview: "/charge-api/devops/overview",
  charge_api_devops_equip_auth: "/charge-api/devops/equipAuth",
  charge_api_devops_start_charge: "/charge-api/devops/startCharge",
  charge_api_devops_stop_charge: "/charge-api/devops/stopCharge",
  charge_api_devops_order_push: "/charge-api/devops/orderPush",
  charge_api_devops_mq_query: "/charge-api/devops/mqQuery",
  charge_api_station_supply: "/charge-api/station-supply",
  charge_api_station_supply_list: "/charge-api/station-supply/list",
  charge_api_station_supply_query: "/charge-api/station-supply/query",
  charge_api_finance: "/charge-api/finance",
  charge_api_finance_resale_account: "/charge-api/finance/resale-account",
  charge_api_finance_account_bill: "/charge-api/finance/account-bill",
  charge_api_cpo_manage: "/charge-api/cpoManage",
  charge_api_finance_pay_callback:"/charge-api/finance/payCallback",
  charge_api_order_manage:"/charge-api/orderManage",
  charge_api_card_manage:"/charge-api/marketing-center/card-manage"
};
export const theme = {
    token: {
      colorPrimary:  "#3698FF",
    },
}
export const headerTheme = {
  background: "rgba(255,255,255,0.80)",
  color: "#363646",
  activeColor: "rgb(24, 144, 255)",
  titleColor: "#333333",
  borderBottomColor: "#C2C2C7",
  userInfo: {
    background: "transparent",
    color: null,
  },
};

