import { SystemMenuItem } from "@/model/data";

export const walkMenus =  (menuOptions:SystemMenuItem[], cb:(item:SystemMenuItem)=>void) => {
    const searchMenus = (menus) => {
      menus.forEach(it => {
        cb(it)
        if (it.children.length) {
          searchMenus(it.children)
        }
      })
    };
    searchMenus(menuOptions)
  };