import { SystemInfoConfig, SystemMenuItem, ValidMenuItem } from "@/model/data";
import { Layout } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Header from "./header";
import "./index.scss";
import api from "../../service/index";
import { routerKeyMap } from "@/config";
import { converPathToUnderLine } from "@/utils/convert";
import Sider from "./sider";
import { walkMenus } from "@/utils/traversal";
import { useLocation } from "react-router-dom";

const { Content } = Layout;
interface LayoutConProps {
  userInfo: any;
  logout: any;
  navigateToUrl: (url)=>void;
  navigateToHome: VoidFunction;
  children: any;
  systemConfig: SystemInfoConfig;
}
const LayoutCon: React.FC<LayoutConProps> = (props) => {
  const {
    userInfo,
    logout,
    navigateToUrl,
    navigateToHome,
    children,
    systemConfig,
  } = props;
  const {pathname} = useLocation()
  const [menusInfo, setMenusInfo] = useState<SystemMenuItem[]>([]);
  const [curNavIds, setCurNavIds] = useState<string[]>([]);
  const [curSiderIds, setCurSiderIds] = useState<string[]>([]);
  const activeApplication = useMemo(() => {
    const localPlatformId = localStorage.getItem('cur_pltformId');
    if (userInfo?.accountInfoList?.length) {
      if (localPlatformId) {
        const active = userInfo.accountInfoList.find(it => it.platformId === localPlatformId);
        if (active) { return active }
      }
      return userInfo?.accountInfoList[0];
    }
    return null;
  }, [userInfo]);
  useEffect(() => {
    if (activeApplication) {
      const params = {
        bizLine: activeApplication?.bizLine,
        platformId: activeApplication?.platformId,
        applicationId: activeApplication?.applicationId,
        mobile: userInfo?.mobile
      }
      api.getMenuList(params).then((res) => {
        setMenusInfo(res.data);
      });
    }

  }, [activeApplication]);
  const currentMenu: SystemMenuItem = useMemo(() => {
    console.log('curNavIds', menusInfo,curNavIds)
    if (menusInfo?.length && curNavIds.length) {
      const keyPath = curNavIds[curNavIds.length - 1];
      const currentItem = menusInfo?.find((it) => it.menuUrlKey === keyPath);
      return currentItem;
    }
    return null;
  }, [menusInfo, curNavIds]);
  console.log('currentMenu===',currentMenu)
  useEffect(() => {
    menusInfo.length &&
      walkMenus(menusInfo, (it) => {
        if ( pathname.includes(routerKeyMap[it.menuUrlKey])) {
          // 设置顶导选中
          setCurNavIds([(it.parentMenuUrlKey || it.menuUrlKey)]);
          // 设置左导选中
          setCurSiderIds([it.menuUrlKey])
        }
      });
  }, [menusInfo,pathname]);
  console.log("currentSideMenu===", curSiderIds);
  const onNavChange = (v) => {
    console.log('v===',v)
    setCurSiderIds([])
    setCurNavIds(v)
  }
  return (
    <Layout className="midway-page-layout" style={{ background: "#f5f5f5" }}>
      <Header
        menus={menusInfo}
        curMenuId={curNavIds}
        onChange={onNavChange}
        userInfo={userInfo}
        logout={logout}
        navigateToUrl={navigateToUrl}
        navigateToHome={navigateToHome}
        systemConfig={systemConfig}
      />
      <Layout>
        {currentMenu?.children?.length ? (
          <Sider
            menuOptions={currentMenu.children}
            curSiderIds={curSiderIds}
            onChange={setCurSiderIds}
            navigateToUrl={navigateToUrl}
          />
        ) : null}
        <Layout>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default LayoutCon;
