export enum ENV {
    DEV = 'dev',
    DAILY = 'daily',
    PRE = 'pre',
    PROD = 'prod',
  }
  
  const hostMap: Record<ENV, string> = {
    [ENV.DEV]: '/',
    [ENV.DAILY]: '/',
    [ENV.PRE]: '//gw-pre.am.xiaojukeji.com',
    [ENV.PROD]: '//gw.am.xiaojukeji.com',
  };
  
  const { hostname } = window.location;
  
  function getEnv() {
    if (process.env.NODE_ENV === "development") {
      return ENV.DEV;
    } else if (
      /open-flow-merchant\.intra\.xiaojukeji\.com/.test(hostname) ||
      /39\.105\.16\.241/.test(hostname) ||
      /dongfeng\.test\.com/.test(hostname)
    ) {
      return ENV.DAILY;
    } else if (
      /open-flow-merchant-pre\.intra\.xiaojukeji\.com/.test(hostname) ||
      /open-energy-pre\.intra\.xiaojukeji\.com/.test(hostname) ||
      /open-energy-midway-pre\.intra\.xiaojukeji\.com/.test(hostname) ||
      /static-pre\.am\.xiaojukeji\.com/.test(hostname)
    ) {
      return ENV.PRE;
    } else if (
      /open-flow-merchant\.xiaojukeji\.com/.test(hostname) ||
      /open-energy\.xiaojukeji\.com/.test(hostname)
    ) {
      return ENV.PROD;
    }
  }
  
  export const isDev = process.env.NODE_ENV === 'development';
  export const env = getEnv();
  
  export const host = hostMap[getEnv()];
  