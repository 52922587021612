import { notification } from 'antd';
import axios,{AxiosResponse} from 'axios';
import { host } from './env';
type Response<T = any> = {
  success: boolean;
  data?: T;
  msg?: string;
};

declare module 'axios' {
  interface AxiosInstance {
    (config: AxiosRequestConfig): Promise<Response>;
  }
}
export const login = () => {
  location.href = '/login' + `?redirectUrl=${encodeURIComponent(location.href)}`
}
const service = axios.create({
  timeout: 10000,
  withCredentials: true,
});
service.interceptors.request.use(
  (config) => {

    return config;
  },
  (error) => console.log('request===', error),
);
service.interceptors.response.use(
  (response) => {
    const { message, msg, success, module, data, code }: any = response.data;
    let suce = success ?? code === 10000;
    if (!suce) {
      notification.error({
        message: '网络请求失败',
        description: message ?? msg ?? '网络错误',
      });
      if (+code == 2002) {
        login()
      }
    }
    return ({
      success: suce,
      data: module ?? data,
      msg: message ?? msg ?? '网络错误',
    } as never as AxiosResponse );
  },
  (error) => {
    notification.error({
      message: '网络请求异常，请稍后重试',
      description: error.toString(),
    });
    return { success: false };
  },
);

async function request<T>(url, data, options, method) {
  if (options)
    return new Promise<Response<T>>((resolve) => {
      service({
        url,
        method,
        baseURL: host,
        ...data,
        ...options,
      })
        .then((res) => {
          const { data, success, msg } = res;
          resolve({ data, success, msg });
        })
        .catch(() => {
          resolve({ success: false });
        });
    });
}

async function get<T>(url, params = {}, options = {}) {
  return request<T>(url, { params }, options, 'GET');
}

async function post<T>(url, params = {}, options = {}) {
  return request<T>(url, { data: params }, options, 'POST');
}

export default { get, post };
