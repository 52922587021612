import { ValidMenuItem } from '@/model/data';
import { converPathToUnderLine } from '@/utils/convert';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './index.scss';

interface AppLayoutProps {
  validMenus: ValidMenuItem[];
}
const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const whiteRouteList = ['base_switch_account'];
  const { validMenus } = props;
  const history = useHistory();
  const { pathname } = useLocation();
  const [parentKeys, setParentKeys] = useState([]);
  const [childKeys, setChildKeys] = useState([]);
  useEffect(() => {
    if (validMenus?.length) {
      const arr =validMenus;
      const parentArr = arr
        .filter((it) => !it.parentMenuUrlKey)
        .map((it) => it.menuUrlKey);
      setParentKeys(parentArr);
      const childArr = arr
        .filter((it) => it.parentMenuUrlKey)
        .map((it) => it.menuUrlKey);
      setChildKeys(childArr);
    }
  }, [validMenus]);
  const redirectToHome = () => {
    setTimeout(() => {
      history.push('/base/home');
    }, 0);
  }
  useEffect(() => {
    console.log('parentKeys===',parentKeys,childKeys)
    if (pathname === '/') {
      redirectToHome()
      return
    }
    if (parentKeys?.length && childKeys?.length) {
      const path = converPathToUnderLine(pathname);
      const isAuth =
      // 子节点 包含关系匹配path 主要是为了兼容详情页情况

        [...childKeys, ...whiteRouteList]?.findIndex((it) =>
          path.includes(it),
        ) > -1 ||
        // 根节点 全等匹配path
        parentKeys.findIndex(it => it === path || `base_${it}` === it);
        console.log('isAuth',isAuth)
      if (!isAuth) {
        redirectToHome()
      }
    }
  }, [pathname, parentKeys, childKeys, whiteRouteList, history]);

  return <div id={'app-container'}></div>;
};

export default AppLayout;
