import { routerKeyMap } from "@/config";
import { SystemMenuItem } from "@/model/data";
import { Layout, Menu, MenuProps } from "antd";

import React, { useEffect, useMemo, useState } from "react";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

interface Props {
  menuOptions: SystemMenuItem[];
  curSiderIds: string[];
  onChange: (v: string[]) => void;
  navigateToUrl: Function;
}
interface State {
  collapsed: boolean;
  menuOptions: any[];
}

const AppMenu: React.FC<Props> = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const { menuOptions, curSiderIds, onChange, navigateToUrl } = props;
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const renderMenuItem = (el: SystemMenuItem) => {
    return (
      <a onClick={() => navigateToUrl(routerKeyMap[el.menuUrlKey])}>
        <span>{el.menuName}</span>
      </a>
    );
  };
  useEffect(() => {
    if (!curSiderIds.length && menuOptions.length > 0) {
      const path = routerKeyMap[menuOptions[0].menuUrlKey];
      history.pushState(null, path, path);
      onChange([menuOptions[0].menuUrlKey]);
    }
  }, [curSiderIds, menuOptions]);
  const MenuItems: MenuProps["items"] = useMemo(() => {
    return menuOptions.map((items) => ({
      key: items.menuUrlKey,
      label: renderMenuItem(items),
      children: items.children.length
        ? items.children?.map((it) => ({
            key: items.menuUrlKey,
            label: renderMenuItem(items),
          }))
        : null,
    }));
  }, [menuOptions]);
  const clickMenu = ({ keyPath }) => {
    onChange(keyPath);
  };
  return (
    <Sider style={{ background: "#fff" }} collapsed={collapsed}>
      <Menu
        mode="inline"
        selectedKeys={curSiderIds}
        onClick={clickMenu}
        items={MenuItems}
      ></Menu>
    </Sider>
  );
};

export default AppMenu;
