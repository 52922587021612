import React, { useMemo } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { UserIcon } from './icons/UserIcon';
import { DownIcon } from './icons/DownIcon';
import './index.scss';
interface UserDropDownProps {
  text: string;
  goToOutLogin: () => void;
  theme: React.CSSProperties;
  navigateToUrl:(url)=>void

}
const UserDropdown: React.FC<UserDropDownProps> = (props) => {
  const { text, goToOutLogin, theme,navigateToUrl } = props;

  const menusItems:MenuProps = {
    items: [
      { label: <a onClick={goToOutLogin}>退出登录</a>, key: 'logout' },
      { label: <a onClick={() => location.href =  '/modifyAccount'}>修改密码</a>, key: 'modifyAccount' },
      // {
      //   label: (
      //     <a onClick={() => location.href ='/base/switch-account'}>切换账号</a>
      //   ),
      //   key: 'switch-accounts',
      // },
    ]
  };
  return (
    <Dropdown menu={menusItems}>
      <div className="user-dropdown-button" style={{ ...theme }}>
        <div className="user-dropdown-left">
          <img src='https://view.didistatic.com/static/dcms/fhq5i1241lgw0vaad_60x60_compress.png'></img>
        </div>
        <DownIcon style={{marginLeft:'8px'}} />
      </div>
    </Dropdown>
  );
};
export default UserDropdown;
