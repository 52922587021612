import React from 'react';
import { Button } from 'antd';
interface LoginProps {
  login:()=>void
}
const Login: React.FC<LoginProps> = (props) => {
  return (
    <Button
      style={{ fontSize: '12px', letterSpacing: 0 }}
      type="primary"
      onClick={props.login}
    >
      登录
    </Button>
  );
};

export default Login;
