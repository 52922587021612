import request from "@/utils/request";
import { ValidMenuItem, AccountInfo, SystemMenuItem } from "@/model/data";
type Response<T = any> = {
  success: boolean;
  data?: T;
  msg?: string;
};

function getMockData<T>(data: T) {
  return null
  return  new Promise<Response<T>>((resolve) =>
    resolve({
      data,
      success: true,
    })
  );
}

export default {
  /**
   * 获取用户权限的菜单
   * @returns ValidMenuItem[]
   */
  getMenuList: (body) =>
    getMockData<SystemMenuItem[]>([
      {
        id: 325,
        createTime: 1675770141000,
        updateTime: 1675770141000,
        menuName: "首页",
        menuUrlKey: "home",
        status: 1,
        menuNodeType: 0,
        parentMenuUrlKey: "",
        bizLine: "charge_api",
        roleAuthList:
          "innerUser,administrator,develop,finance,operation,customerService",
        menuDesc: "首页模块",
        feature: null,
        menuOrder: 0,
        children: [],
      },
      {
        id: 327,
        createTime: 1675770141000,
        updateTime: 1675770141000,
        menuName: "运维中心",
        menuUrlKey: "charge_api_devops",
        status: 1,
        menuNodeType: 0,
        parentMenuUrlKey: "",
        bizLine: "charge_api",
        roleAuthList: "innerUser,administrator,develop,operation",
        menuDesc: "运维中心",
        feature: null,
        menuOrder: 1,
        children: [
          {
            id: 329,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "运维指标概览",
            menuUrlKey: "charge_api_devops_overview",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_devops",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,develop,operation",
            menuDesc: "运维指标概览",
            feature: null,
            menuOrder: 11,
            children: [],
          },
          {
            id: 331,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "设备认证记录",
            menuUrlKey: "charge_api_devops_equip_auth",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_devops",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,develop,operation",
            menuDesc: "设备认证记录",
            feature: null,
            menuOrder: 12,
            children: [],
          },
          {
            id: 333,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "启动充电记录",
            menuUrlKey: "charge_api_devops_start_charge",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_devops",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,develop,operation",
            menuDesc: "启动充电记录",
            feature: null,
            menuOrder: 13,
            children: [],
          },
          {
            id: 335,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "停止充电记录",
            menuUrlKey: "charge_api_devops_stop_charge",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_devops",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,develop,operation",
            menuDesc: "停止充电记录",
            feature: null,
            menuOrder: 14,
            children: [],
          },
          {
            id: 337,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "订单推送记录",
            menuUrlKey: "charge_api_devops_order_push",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_devops",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,develop,operation",
            menuDesc: "订单推送记录",
            feature: null,
            menuOrder: 15,
            children: [],
          },
          {
            id: 339,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "MQ消息记录",
            menuUrlKey: "charge_api_devops_mq_query",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_devops",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,develop,operation",
            menuDesc: "MQ消息记录",
            feature: null,
            menuOrder: 16,
            children: [],
          },
        ],
      },
      {
        id: 341,
        createTime: 1675770141000,
        updateTime: 1675770141000,
        menuName: "场站供给",
        menuUrlKey: "charge_api_station_supply",
        status: 1,
        menuNodeType: 0,
        parentMenuUrlKey: "",
        bizLine: "charge_api",
        roleAuthList: "innerUser,administrator,develop,operation",
        menuDesc: "场站供给",
        feature: null,
        menuOrder: 2,
        children: [
          {
            id: 343,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "场站列表查询",
            menuUrlKey: "charge_api_station_supply_list",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_station_supply",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,develop,operation",
            menuDesc: "场站列表查询",
            feature: null,
            menuOrder: 21,
            children: [],
          },
          {
            id: 345,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "查询场站记录",
            menuUrlKey: "charge_api_station_supply_query",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_station_supply",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,develop,operation",
            menuDesc: "查询场站记录",
            feature: null,
            menuOrder: 22,
            children: [],
          },
        ],
      },
      {
        id: 347,
        createTime: 1675770141000,
        updateTime: 1675770141000,
        menuName: "资金对账",
        menuUrlKey: "charge_api_finance",
        status: 1,
        menuNodeType: 0,
        parentMenuUrlKey: "",
        bizLine: "charge_api",
        roleAuthList: "innerUser,administrator,finance",
        menuDesc: "资金对账",
        feature: null,
        menuOrder: 3,
        children: [
          {
            id: 349,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "渠道账户管理",
            menuUrlKey: "charge_api_finance_resale_account",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_finance",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,finance",
            menuDesc: "转售渠道使用KA账户管理",
            feature: {
              bizChannelName: "小桔引入转售渠道",
            },
            menuOrder: 31,
            children: [],
          },
          {
            id: 353,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "渠道账单管理",
            menuUrlKey: "charge_api_finance_account_bill",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "charge_api_finance",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator,finance",
            menuDesc: "渠道账单能力由资金侧统一提供",
            feature: null,
            menuOrder: 33,
            children: [],
          },
        ],
      },
      {
        id: 359,
        createTime: 1675770141000,
        updateTime: 1675770141000,
        menuName: "帐号中心",
        menuUrlKey: "account_center",
        status: 1,
        menuNodeType: 0,
        parentMenuUrlKey: "",
        bizLine: "charge_api",
        roleAuthList: "innerUser,administrator",
        menuDesc: "帐号中心",
        feature: null,
        menuOrder: 6,
        children: [
          {
            id: 361,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "角色管理",
            menuUrlKey: "account_center_role_manage",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "account_center",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator",
            menuDesc: "角色管理",
            feature: null,
            menuOrder: 61,
            children: [],
          },
          {
            id: 363,
            createTime: 1675770141000,
            updateTime: 1675770141000,
            menuName: "员工管理",
            menuUrlKey: "account_center_user_manage",
            status: 1,
            menuNodeType: 1,
            parentMenuUrlKey: "account_center",
            bizLine: "charge_api",
            roleAuthList: "innerUser,administrator",
            menuDesc: "员工管理",
            feature: null,
            menuOrder: 62,
            children: [],
          },
        ],
      },
    ]) || request.post<ValidMenuItem[]>("/gaia/tenant/getMenuList",body),
  /**
   * 获取用户信息
   * @returns AccountInfo
   */
  getAccountList: () =>
    getMockData<AccountInfo>({
      mobile: "17858180597",
      name: "孙振宇",
      accountInfoList: [
        {
          bizLine: "charge_api",
          applicationId: 10274,
          applicationName: "货拉拉",
          roleType: "administrator",
          roleName: "管理员",
          platformId: "329527377",
          platformName: "货拉拉",
          platformAccountType: 1,
          platformChannelId: 88,
          merchantId: "884858417627963211",
          kaId: 1437,
        },
        {
          bizLine: "charge_api",
          applicationId: 10168,
          applicationName: "蔚来nio",
          roleType: "administrator",
          roleName: "管理员",
          platformId: "MA4KUHJ97",
          platformName: "蔚来nio",
          platformAccountType: 1,
          platformChannelId: 83,
          merchantId: "880808105491417974",
          kaId: 503,
        },
        {
          bizLine: "charge_api",
          applicationId: 10268,
          applicationName: "上汽R",
          roleType: "administrator",
          roleName: "管理员",
          platformId: "MA1FR0P33",
          platformName: "上汽R",
          platformAccountType: 1,
          platformChannelId: 121,
          merchantId: "917775483359121088",
          kaId: null,
        },
        {
          bizLine: "charge_api",
          applicationId: 10208,
          applicationName: "特能行",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "191110108",
          platformName: "特能行",
          platformAccountType: 1,
          platformChannelId: 48,
          merchantId: "990560603740760739",
          kaId: 817,
        },
        {
          bizLine: "charge_api",
          applicationId: 12306,
          applicationName: "沃尔沃(测试1)",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "CTS126610",
          platformName: "沃尔沃(测试1)",
          platformAccountType: 2,
          platformChannelId: 0,
          merchantId: "0",
          kaId: 2418,
        },
        {
          bizLine: "charge_api",
          applicationId: 10276,
          applicationName: "哪吒",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "MA1GC24G0",
          platformName: "哪吒",
          platformAccountType: 1,
          platformChannelId: 79,
          merchantId: "865626490016548978",
          kaId: 1443,
        },
        {
          bizLine: "charge_api",
          applicationId: 10178,
          applicationName: "地上铁租车（深圳）有限公司",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "334964192",
          platformName: "地上铁租车（深圳）有限公司",
          platformAccountType: 1,
          platformChannelId: 61,
          merchantId: "841974180937787523",
          kaId: 793,
        },
        {
          bizLine: "charge_api",
          applicationId: 12308,
          applicationName: "智电未来信息科技有限公司",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "344275981",
          platformName: "智电未来信息科技有限公司",
          platformAccountType: 1,
          platformChannelId: 1323124334,
          merchantId: "1049283161556508600",
          kaId: 2378,
        },
        {
          bizLine: "charge_api",
          applicationId: 10124,
          applicationName: "达芬奇",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "A01CF8L75",
          platformName: "达芬奇",
          platformAccountType: 1,
          platformChannelId: 1323124171,
          merchantId: "0",
          kaId: null,
        },
        {
          bizLine: "charge_api",
          applicationId: 10434,
          applicationName: "达芬奇公充",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "A01CF8L76",
          platformName: "达芬奇公充",
          platformAccountType: 1,
          platformChannelId: 1323124234,
          merchantId: "935220424432312154",
          kaId: null,
        },
        {
          bizLine: "charge_api",
          applicationId: 10582,
          applicationName: "哈啰出行",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "HELLO2323",
          platformName: "哈啰出行",
          platformAccountType: 1,
          platformChannelId: 1323124259,
          merchantId: "1002233735950301786",
          kaId: null,
        },
        {
          bizLine: "charge_api",
          applicationId: 10260,
          applicationName: "浙江绿色慧联正式",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "MA28M65KX",
          platformName: "浙江绿色慧联正式",
          platformAccountType: 1,
          platformChannelId: 77,
          merchantId: "860492488827939075",
          kaId: 1309,
        },
        {
          bizLine: "charge_api",
          applicationId: 10360,
          applicationName: "理想充电网络",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "MA7EBGWBX",
          platformName: "理想充电网络",
          platformAccountType: 1,
          platformChannelId: 1323124206,
          merchantId: "966726241799889329",
          kaId: 1845,
        },
        {
          bizLine: "charge_api",
          applicationId: 12384,
          applicationName: "南京领行科技股份有限公司",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "MA1Y9HPJX",
          platformName: "南京领行科技股份有限公司",
          platformAccountType: 1,
          platformChannelId: 1323124344,
          merchantId: "1056946686336330604",
          kaId: 2438,
        },
        {
          bizLine: "charge_api",
          applicationId: 12388,
          applicationName: "沃尔沃PROD",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "120726546",
          platformName: "沃尔沃PROD",
          platformAccountType: 1,
          platformChannelId: 1323124345,
          merchantId: "1057690963372731429",
          kaId: 2432,
        },
        {
          bizLine: "charge_api",
          applicationId: 12388,
          applicationName: "沃尔沃PRE(测试1)",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "CTS129493",
          platformName: "沃尔沃PRE(测试1)",
          platformAccountType: 2,
          platformChannelId: 1323124345,
          merchantId: "1057690963372731429",
          kaId: 2420,
        },
        {
          bizLine: "charge_api",
          applicationId: 10404,
          applicationName: "上汽通用",
          roleType: "innerUser",
          roleName: "内部用户",
          platformId: "607371748",
          platformName: "上汽通用",
          platformAccountType: 1,
          platformChannelId: 1323124287,
          merchantId: "1029705810554435602",
          kaId: null,
        },
      ],
    }) || request.get<AccountInfo>("/gaia/user/getAccountList"),
  /**
   * 登录接口
   */
  login: (body) => request.post("/gaia/cookie/loginIn", body),
  /**
   * 登出接口
   */
  loginOut: () => request.post("/gaia/cookie/loginOut"),
  /**
   * 修改密码
   */
  modifyPassword: (body) => request.post("/gaia/cookie/modifyPassword", body),
};
