import React, { useMemo } from 'react';
import Login from './Login';
import UserDropdown from './Dropdown';
import './index.scss';
interface UserInfoProps {
  userInfo: any;
  theme: React.CSSProperties;
  goToOutLogin: () => void;
  login:VoidFunction
  navigateToUrl:(url)=>void
}
const UserInfo: React.FC<UserInfoProps> = (props) => {
  const { userInfo, goToOutLogin, theme,login,navigateToUrl } = props;
  const activeApplication = useMemo(() => {
    const localPlatformId = localStorage.getItem('cur_pltformId');
    if (userInfo?.accountInfoList?.length) {
      if (localPlatformId) {
        const active = userInfo.accountInfoList.find(it => it.platformId === localPlatformId);
        if (active) { return active }
      }
      return userInfo?.accountInfoList[0];
    }
    return null;
  }, [userInfo]);
  console.log('userInfo===',userInfo)
  const text = activeApplication?.platformName || userInfo?.phone || userInfo?.mobile || userInfo?.name || '-';
  return (
    <div className="info-wrap">
      {text ? (
        <UserDropdown text={text} theme={theme} navigateToUrl={navigateToUrl} goToOutLogin={goToOutLogin} />
      ) : (
        <Login login={login} />
      )}
    </div>
  );
};

export default UserInfo;
