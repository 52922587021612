
/**
 * 转换url字符串的协议字段
 * @param url
 * @returns
 */
export const convertUrlProtocol = (url) => {
  const protocolList = ['http', 'https'];
  const currentProtocol = window.location.protocol;
  const urlArr = url.split('://');
  if (urlArr.length === 2 && protocolList.includes(urlArr[0])) {
    return currentProtocol + '//' + urlArr[1];
  } else if (urlArr.length === 1) {
    return currentProtocol + url;
  }
  return url;
};

export const converPathToUnderLine = (url) => {
  // 大写字母 - / 都转下划线
  return url.slice(1).replace(/(\/|-|[A-Z])/g, (v) => {
    return v !== '-' && v !== '/' ? `_${v.toLowerCase()}` : '_';
  });
};
