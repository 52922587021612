import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import { ConfigProvider } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LayoutCon from "@/components/system-layout";
import AppRender from "@/components/app-render";
import api from "@/service";
import { systemConfig } from "./config";
import { AccountInfo, ValidMenuItem } from "./model/data";
import Login from "./pages/login";
import {registerMicroApps,setMicroAppProps,navigateToUrl} from  "@/core/qiankun";
import service from "@/service";
function App() {
  const [userInfo, setUserInfo] = useState<AccountInfo>(null);
  const [topMenuInfo, setTopMenuInfo] = useState<ValidMenuItem[]>([]);
  const [sideMenus, setVaildMenus] = useState<ValidMenuItem[]>([]);
  const goToOutLogin = () => {
    console.log("登出功能");
    service.loginOut().then(res => {
      const {success} = res
      console.log('res===',res)
      success && (location.href = '/login')
    })
  };

  const navigate = (url) => {
    if (/^(http(s)?:)?\/\//.test(url)) {
      window.open(url, "_blank");
    } else {
      console.log('url===',url)
      navigateToUrl(url);
    }
  };

  const navigateToHome = () => {
    navigateToUrl("/base/home");


  };
  const loginPathArr = ["/login","/modifyAccount"]
  useEffect(() => {
    if (!loginPathArr.includes(window.location.pathname)) {
      api.getAccountList().then((res) => {
        setUserInfo(res.data);
        setMicroAppProps('userInfo', res.data)
        
        registerMicroApps()
      });
    }
  }, []);

  return (
    <ConfigProvider theme={systemConfig.antdTheme}>
      <StyleProvider hashPriority="high">
        <Router>
          {loginPathArr.includes(window.location.pathname) ? (
            <Switch>
              <Route path={"/login"} component={Login}></Route>
              <Route path={"/modifyAccount"} component={Login}></Route>
            </Switch>
          ) : (
            <LayoutCon
              systemConfig={systemConfig}
              userInfo={userInfo}
              logout={goToOutLogin}
              navigateToUrl={navigate}
              navigateToHome={navigateToHome}
            >
              <Switch>
                {/* <Route
                  component={() => <div>no auth</div>}
                  path={"/:app/:root/auth"}
                  exact
                ></Route> */}
                <Route
                  render={() => <AppRender validMenus={sideMenus} />}
                  path={"/"}
                ></Route>
              </Switch>
            </LayoutCon>
          )}
        </Router>
      </StyleProvider>
    </ConfigProvider>
  );
}

export default App;
