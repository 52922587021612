import React, { useEffect, useMemo, useState } from "react";
import { systemConfig } from "@/config";
import "./index.scss";
import { Button, Card, Form, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import service from "@/service/index";
import qs from "qs";
import { useLocation } from "react-router-dom";
type LoginProps = {};

const Login: React.FC<LoginProps> = (props) => {
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  console.log("path", pathname);
  const isModifyPage = useMemo(() => pathname === "/modifyAccount", [pathname]);
  useEffect(()=>{

  },[])
  const submit = (values) => {
    console.log("values==", values);
    setSubmitLoding(true);
    if (isModifyPage) {
      const { newPasswordConfirm, ...params } = values;
      service
        .modifyPassword(params)
        .then(({ success }) => {
          message.success('密码修改成功，请重新登陆')
          success && history.pushState(null, null, "/login");
        })
        .finally(() => {
          setSubmitLoding(false);
        });
    } else {
      service
        .login(values)
        .then(({ success }) => {
          if (success) {
            const { redirectUrl } = qs.parse(location.search.slice(1));
            location.href =  redirectUrl ? redirectUrl.toString() : "/base/home"
          }
        })
        .finally(() => {
          setSubmitLoding(false);
        });
    }
  };
  const [submitLoding, setSubmitLoding] = useState(false);
  const toModify = () => {
    location.href = '/modifyAccount'
  };
  const pwdRule = {
    message: "密码长度需要8-16位，且包含数字和字母",
    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
    required: true,
  };
  return (
    <div className="page-login" style={{backgroundImage:`url(${systemConfig.loginBackgroundImg})`}}>
      <div className="page-header">
        <img width={systemConfig.whiteLogo.width} height={systemConfig.whiteLogo.height} src={systemConfig.whiteLogo.url}></img>
      </div>
      <div className="page-content">
        <Card
          className="page-form-card"
          title={isModifyPage ? "密码修改" : "用户登录"}
        >
          <Form
            className="page-form"
            labelCol={{ span: 5 }}
            form={form}
            onFinish={submit}
          >
            <Form.Item
              name="mobile"
              label="用户名"
              rules={[
                {
                  required: true,
                  message: "请输入正确的手机号",
                  pattern: /^1[3456789]\d{9}$/,
                },
              ]}
            >
              <Input
                addonBefore="+ 86"
                size="large"
                allowClear
                placeholder="请输入用户手机号"
              ></Input>
            </Form.Item>
            {isModifyPage ? (
              <>
                <Form.Item
                  name={"oldPassword"}
                  label="原密码"
                  rules={[
                    {
                      required: true,
                      message: "请输入正确密码",
                    },
                    pwdRule,
                  ]}
                >
                  <Input.Password
                    placeholder="请输入原密码"
                    size="large"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={"newPassword"}
                  label="新密码"
                  rules={[
                    {
                      required: true,
                      message: "请输入新密码",
                    },
                    pwdRule,
                  ]}
                >
                  <Input.Password
                    placeholder="请输入新密码"
                    size="large"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={"newPasswordConfirm"}
                  label="再次确认"
                  rules={[
                    {
                      required: true,
                      message: "请再次确认新密码",
                    },
                    pwdRule,
                    {
                      validator(_, value) {
                        if (value !== form.getFieldValue("newPassword")) {
                          return Promise.reject(new Error('两次输入的密码不一致'))
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="请输入新密码"
                    size="large"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                name="password"
                label="密码"
                rules={[
                  {
                    required: true,
                    message: "请输入正确密码",
                  },
                  pwdRule,
                ]}
              >
                <Input.Password
                  autoComplete="on"
                  placeholder="请输入密码"
                  size="large"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            )}
        
            <Button
              loading={submitLoding}
              style={{ width: "100%" }}
              type="primary"
              size="large"
              // htmlType="submit"
              onClick={()=>form.submit()}
            >
              {isModifyPage ? "确认修改" : "登录"}
            </Button>
          </Form>
          <div className="page-form-card-footer">
            {isModifyPage ? (
              <div onClick={() => location.href = '/login'}>
                返回登录
              </div>
            ) : (
              <div onClick={toModify}>修改密码</div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Login;
