import { login } from "@/utils/request";
import { registerMicroApps as register, start,addGlobalUncaughtErrorHandler } from "qiankun";
import { navigateToUrl } from "single-spa";
import {systemConfig} from '@/config'
addGlobalUncaughtErrorHandler((event) => console.log('qiankunGlobalUncaughtError:',event));
window.__IS_BOOTSTRAPED_BY_MIDWAY__ = true;
const microAppConfig = [
  {
    name: "open-flow-merchant-home",
    entry: "/child/df-fe-home/",
    // entry: "http://localhost:8080/index.html",
    container: "#app-container",
    activeRule: "/base",
    props: {
      systemConfig
    },
  },
  {
    name: "open-flow-merchant-charge",
    entry: "/child/df-fe-charge/",
    container: "#app-container",
    activeRule: "/charge-api",
    props: {
      systemConfig
    },
  },
];
 const setMicroAppProps = (key, value) => {
  microAppConfig.forEach((it) => {
    it.props[key] = value;
  });
};
 const registerMicroApps = () => {
  setMicroAppProps('login', login)
  register(microAppConfig, {
    beforeLoad: [
      (app) => {
        console.log(
          "[qiankun-life-cycle] before load %c%s",
          "color: green;",
          app.name
        );
        return Promise.resolve(true);
      },
    ],
    beforeMount: [
      (app) => {
        console.log(
          "[qiankun-life-cycle] before mount %c%s",
          "color: green;",
          app.name
        );
        return Promise.resolve(true);
      },
    ],
    afterMount: [
      (app) => {
        console.log(
          "[qiankun-life-cycle] after mount %c%s",
          "color: green;",
          app.name
        );
        return Promise.resolve(true);
      },
    ],
    beforeUnmount: [
      (app) => {
        console.log(
          "[qiankun-life-cycle] before unmount %c%s",
          "color: green;",
          app.name
        );
        return Promise.resolve(true);
      },
    ],
    afterUnmount: [
      (app) => {
        console.log(
          "[qiankun-life-cycle] after unmount %c%s",
          "color: green;",
          app.name
        );
        return Promise.resolve(true);
      },
    ],
  });
  start({
    singular: false,
    sandbox: {
      experimentalStyleIsolation: true,
      speedy: true,
    },
  });
};

export {
  navigateToUrl,
  registerMicroApps,
  setMicroAppProps
}