import { headerTheme, routerKeyMap } from "@/config";
import { SystemInfoConfig, SystemMenuItem } from "@/model/data";
import { Layout, Menu } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import UserInfo from "./components/UserInfo";
import type { MenuProps } from "antd";
import { useHistory } from "react-router-dom";
const { Header } = Layout;

interface TopHeaderProps {
  userInfo: any;
  menus: SystemMenuItem[];
  navigateToHome: Function;
  logout: Function;
  navigateToUrl: (url) => void;
  curMenuId: Array<string>;
  systemConfig: SystemInfoConfig;
  onChange: (v) => void;
}
const TopHeader: React.FC<TopHeaderProps> = (props) => {
  const {
    userInfo,
    menus,
    navigateToHome,
    navigateToUrl,
    logout,
    systemConfig,
    curMenuId,
    onChange,
  } = props;
  const history = useHistory();
  const goToOutLogin = () => {
    logout();
  };

  const onMenuClick = ({ keyPath, key }) => {
    onChange(keyPath);
    history.push(routerKeyMap[key]);
  };
  const items: MenuProps["items"] = useMemo(() => {
    return menus.map((it) => ({ label: it.menuName, key: it.menuUrlKey }));
  }, [menus]);
  
  return (
    <Header
      className={`top-header`}
      style={{
        background: headerTheme.background,
        borderBottom: `1px solid ${
          headerTheme.borderBottomColor ?? "transparent"
        }`,
      }}
    >
      <div className="logo-container" onClick={() => navigateToHome()}>
        {systemConfig.logo && (
          <div className="logo-box">
            <img className="logo-title" alt="logo" src={systemConfig.logo.url} width={systemConfig.logo.width} height={systemConfig.logo.height}></img>
            <div className="logo-divider"></div>
            <div
              className="logo-platform"
              style={{ color: headerTheme.titleColor }}
            >
              {systemConfig.name}
            </div>
          </div>
        )}
      </div>
      <Menu
        className="top-menu-container"
        onClick={onMenuClick}
        selectedKeys={curMenuId}
        mode="horizontal"
        items={items}
      />

      <div className="user-info-wrap">
        <UserInfo
          navigateToUrl={navigateToUrl}
          userInfo={userInfo}
          login={goToOutLogin}
          goToOutLogin={goToOutLogin}
          theme={headerTheme.userInfo}
        ></UserInfo>
      </div>
    </Header>
  );
};
export default TopHeader;
